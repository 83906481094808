import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { ROUTE_TRANSLATIONS } from './app-routing.config';

const routes: Routes = [
    {
        path: 'hitta',
        loadChildren: () => import('./browse/browse.module').then((m) => m.AppBrowseModule)
    },
    {
        path: 'inspiration',
        loadChildren: () =>
            import('./inspiration/inspiration.module').then((m) => m.InspirationModule)
    },
    {
        path: ROUTE_TRANSLATIONS['tema'],
        loadChildren: () => import('./articles/article.module').then((m) => m.AppArticleModule)
    },
    {
        path: ROUTE_TRANSLATIONS['antligen-helg'],
        loadChildren: () => import('./articles/article.module').then((m) => m.AppArticleModule)
    },
    {
        path: ROUTE_TRANSLATIONS['snurra-flaskan'],
        loadChildren: () => import('./articles/article.module').then((m) => m.AppArticleModule)
    },
    {
        path: ROUTE_TRANSLATIONS['fredagstipset'],
        loadChildren: () => import('./articles/article.module').then((m) => m.AppArticleModule)
    },
    {
        path: 'product',
        loadChildren: () => import('./product/product.module').then((m) => m.AppProductModule)
    },
    {
        path: 'valkommen',
        loadChildren: () => import('./welcome/welcome.module').then((m) => m.AppWelcomeModule)
    },
    {
        path: 'info',
        loadChildren: () => import('./info/info.module').then((m) => m.AppInfoModule)
    },
    {
        path: 'om-oss',
        loadChildren: () => import('./about/about.module').then((m) => m.AppAboutModule)
    },
    {
        path: '404',
        loadChildren: () => import('./404/404.module').then((m) => m.App404Module)
    },
    {
        path: '',
        loadChildren: () => import('./home/home.module').then((m) => m.AppHomeModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledBlocking',
            scrollPositionRestoration: 'enabled',
            preloadingStrategy: PreloadAllModules,
            anchorScrolling: 'enabled'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
