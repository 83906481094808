import { isPlatformBrowser } from '@angular/common';
import {
    ApplicationRef,
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit,
    PLATFORM_ID,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';

import { filter, take } from 'rxjs/operators';

import { VbAuthBrowserFacadeService } from '@vb/auth/browser';

import { environment } from '../environments/environment';
import { versions } from '../versions';
import { AppAttributionService } from './app-attribution.service';
import { AppHelperService } from './app-helper.service';
import { AppMainmenuComponent } from './shared/navigation/mainmenu/mainmenu.component';
import { AppSharedNewsletterService } from './shared/newsletter/newsletter.service';
import { AppTrackerService } from './shared/tracker/tracker.service';
import { RootFacade } from './state/root/root.facade';
import { SignupStatus } from './state/root/root.reducer';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
    loadingUser$ = this._authFacade.loading$;
    user$ = this._authFacade.currentUser$;
    userError$ = this._authFacade.userError$;

    versions = versions;

    mainmenuStatus = false;

    @ViewChild(AppMainmenuComponent) mainmenu!: AppMainmenuComponent;

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private _appRef: ApplicationRef,
        private _sanitizer: DomSanitizer,
        private _router: Router,
        private _matIconRegistry: MatIconRegistry,
        private _appHelper: AppHelperService,
        private _rootFacade: RootFacade,
        private _authFacade: VbAuthBrowserFacadeService,
        private _appAttribution: AppAttributionService,
        private _appTracker: AppTrackerService,
        private _newsletterDialog: AppSharedNewsletterService
    ) {
        this._registerIcons();
    }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this._appRef.isStable
                .pipe(
                    filter((s) => !!s),
                    take(1)
                )
                .subscribe(() => {
                    this._rootFacade.markAsLoaded();
                    this._appTracker.ready$.subscribe({
                        next: () => {
                            this._appAttribution.init();
                        }
                    });

                    // Load segment
                    this._appTracker.load({
                        writeKey: environment.segment.apiKey
                    });

                    // Mark state as navigated on first NavigationStart
                    this._router.events
                        .pipe(
                            filter((e) => e instanceof NavigationStart),
                            take(1)
                        )
                        .subscribe(() => {
                            this._rootFacade.markAsNavigated();
                        });

                    // Detect if user has already registered and convert to new
                    // signupStatus state property.
                    if (localStorage.getItem('_vb.signupPending')) {
                        this._rootFacade.setSignupStatus(SignupStatus.COMPLETED);
                        localStorage.removeItem('_vb.signupPending');
                    }
                });
        }
    }

    register() {
        this._newsletterDialog.openRegisterDialog();
    }

    toggleMenu() {
        this.mainmenu?.toggle();
    }

    private _registerIcons() {
        const baseUrl = this._appHelper.getCurrentBaseUrl();

        this._matIconRegistry.setDefaultFontSetClass('material-symbols-outlined');

        [
            'arrow-left',
            'arrow-right',
            'check',
            'chevron-down',
            'chevron-up',
            'close',
            'external',
            'graph',
            'login',
            'menu',
            'plus',
            'search',
            'shortcut',
            'star',
            'instagram',
            'facebook',
            'instagram',
            'linkedin',
            'twitter',
            'mail',
            'share',
            'expand',
            'minimize',
            'check-circle'
        ].forEach((icon) => {
            this._matIconRegistry.addSvgIcon(
                icon,
                this._sanitizer.bypassSecurityTrustResourceUrl(
                    `${baseUrl}/assets/icons/ui/${icon}.svg`
                ),
                { viewBox: '0 0 24 24' }
            );
        });

        [
            'beer-ale',
            'beer-dark',
            'beer-light',
            'beer-medium',
            'beer-light-other',
            'beer-light-sour',
            'beer-light-wheat',
            'cider-cider',
            'cider-mixed',
            'spirit-cocktail',
            'spirit-dark',
            'spirit-white',
            'spirit-liqueur',
            'spirit-liqueur-cream',
            'spirit-punsch',
            'spirit-whisky',
            'wine-crate',
            'wine-red',
            'wine-white',
            'wine-sparkling',
            'wine-rose',
            'wine-sparkling-rose',
            'wine-fruit',
            'wine-mixed',
            'wine-sake',
            'wine-vermouth',
            'wine-orange',
            'wine-orange-sparkling',
            'wine-dessert-fortified',
            'wine-dessert-red',
            'wine-dessert-white',
            'wine-dessert-gluhwein'
        ].forEach((icon) => {
            this._matIconRegistry.addSvgIcon(
                icon,
                this._sanitizer.bypassSecurityTrustResourceUrl(
                    `${baseUrl}/assets/icons/category/${icon}.svg`
                ),
                { viewBox: '0 0 40 40' }
            );
        });

        [
            'taste-gronsaker',
            'taste-flask',
            'taste-dessert',
            'taste-avec',
            'taste-aperitif',
            'taste-vilt',
            'taste-skaldjur',
            'taste-sallskapsdryck',
            'taste-ost',
            'taste-not',
            'taste-lamm',
            'taste-kryddstark',
            'taste-fisk',
            'taste-fagel',
            'taste-buffemat',
            'taste-asiatiskt',
            'grape',
            'restaurant',
            'multiple',
            'eco'
        ].forEach((icon) => {
            this._matIconRegistry.addSvgIcon(
                icon,
                this._sanitizer.bypassSecurityTrustResourceUrl(
                    `${baseUrl}/assets/icons/taste/${icon}.svg`
                ),
                { viewBox: '0 0 40 40' }
            );
        });

        ['score-badge', 'numerical-score-badge'].forEach((icon) => {
            this._matIconRegistry.addSvgIcon(
                icon,
                this._sanitizer.bypassSecurityTrustResourceUrl(
                    `${baseUrl}/assets/icons/badge/${icon}.svg`
                ),
                { viewBox: '0 0 40 40' }
            );
        });
    }
}
