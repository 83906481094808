import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { delay, filter, map, switchMap, take } from 'rxjs/operators';

import * as UserActions from '@vb/auth/browser/state/user/user.actions';

import * as RootActions from './root.actions';
import { AppAttributionService } from '../../app-attribution.service';
import { AppSharedNewsletterService } from '../../shared/newsletter/newsletter.service';
import { AppTrackerService } from '../../shared/tracker/tracker.service';
import { AppRootService } from '../root.service';
import { RootFacade } from './root.facade';
import { SignupStatus } from './root.reducer';

@Injectable()
export class RootEffects {
    onAppLoaded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RootActions.markAsLoaded),
            map(() => {
                const status = this._rootService.getSignupStatusFromCookie();

                return RootActions.setSignupStatus({ status });
            })
        )
    );

    // onSignupStatusChange$ = createEffect(
    //     () =>
    //         this.actions$.pipe(
    //             ofType(RootActions.setSignupStatus),
    //             switchMap(() => this._rootFacade.signupStatus$.pipe(take(1))),
    //             map((status) => {
    //                 this._rootService.saveSignupStatusToCookie(status);
    //             })
    //         ),
    //     { dispatch: false }
    // );

    onVerifyCookiesSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RootActions.verifyCookiesSuccess),
                map(() => {
                    if (isPlatformBrowser(this.platformId)) {
                        of(null)
                            .pipe(
                                take(1),
                                delay(15000),
                                switchMap(() => this._rootFacade.signupStatus$.pipe(take(1))),
                                filter(
                                    (status) =>
                                        ![SignupStatus.COMPLETED, SignupStatus.PENDING].includes(
                                            status
                                        )
                                )
                            )
                            .subscribe(() => {
                                if (!this._newsletterService.isRegistrationPrompted()) {
                                    this._newsletterService.openRegisterDialog();
                                }
                            });
                    }
                })
            ),
        { dispatch: false }
    );

    onUserRegistration$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UserActions.registerSuccess),
                map(({ user }) => {
                    try {
                        if (!user.isNew) {
                            this._tracker.track('User Registration Duplicate');
                        } else {
                            this._tracker.track('User Registration Complete');

                            const attributionContext = this._appAttribution.context;

                            if (attributionContext?.first) {
                                user.registration = {
                                    ...attributionContext.first
                                };
                            }
                        }

                        const userPayload = { ...user };

                        delete userPayload.isNew;

                        // Identify user through Segment
                        this._tracker.identify(user.id, userPayload);

                        // Set registration dialog cookie
                        this._newsletterService.setRegisterCookie();
                    } catch (error) {
                        console.log(error);
                    }
                })
            ),
        { dispatch: false }
    );

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private actions$: Actions,
        private _rootService: AppRootService,
        private _tracker: AppTrackerService,
        private _appAttribution: AppAttributionService,
        private _newsletterService: AppSharedNewsletterService,
        private _rootFacade: RootFacade
    ) {}
}
