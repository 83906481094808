import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';

import { AppSharedNewsletterModule } from '../newsletter/newsletter.module';
import { AppFooterComponent } from './app-footer.component';

@NgModule({
    declarations: [AppFooterComponent],
    exports: [AppFooterComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatListModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,

        AppSharedNewsletterModule,

        RouterModule,
        CommonModule
    ]
})
export class AppFooterModule {}
